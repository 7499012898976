.padding-40 {
  padding: 40px 0;
}

.center {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
}

.country_code {
  margin: 0;
}

.list-style-none {
  list-style: none;
}

.flag {
  width: 30px;
  height: 30px;
}

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .categories {
    display: none;
  }

  .category-search-btn {
    display: block;
  }

  .categories-visible {
    width: 100%;
    display: -webkit-flex;
    z-index: 1;
    position: absolute;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
  }
}

.pagination{
  justify-content: center;
}

.nonVisible {
  display: none;
}

.plusMinusIcon {
  padding-top: .6rem!important;
  position: absolute;
  z-index: 10;
  cursor: pointer;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 22px 0;
  border-radius: 4px;
  cursor: pointer;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #1b4060;
  background-color: #eeeeee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  background-color: #2c689c;
  border-color: #2c689c;
  cursor: default;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.alert.alert-server {
  margin-bottom: 3px;
  border-radius: 0;
}

.link-black {
  color: black;
}
.link-black:hover {
  color: #00F
}

.pl-0i {
  padding-left: 0 !important;
}

.back-to-top {
  position: fixed;
  bottom: 200px;
  right: 100px;
}
