.parentImage {
    width: 100%;
    height: 100%;
}

.indomitusImage {
    max-width: 800px;
    max-height: 800px;
    width: 100%;
    height: 100%;
}

.cartImage {
    width: 60px;
    height: 60px;
}

.price {
    color: black;
    font-weight: bold;
}

.product {
    padding: 20px;
    height: 100%;
    margin-bottom: 20px;
    background-color: white;
}

.product-image-block:hover {
    cursor: pointer;
}

.product-name-block:hover {
    cursor: pointer;
}

.product-image-block {
    text-align: center;
    margin: 0 0 10px;
    display: table;
    width: 100%;
}

.admin-product-image {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 150px;
    width: 150px;
}

.product_image {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-height: 250px;
    width: 250px;
}

.paint-search {
    max-width: 300px;
}

.product-name-block {
    min-height: 15%;
    font-size: 22px;
    line-height: 1.5;
    text-align: center;
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.product-name {
    display: table-cell;
    vertical-align: middle;
    font-size: 22px;
    line-height: 1.5;
    color: black;
}
a:hover {
    text-decoration: underline;
    color: black;
}

@media (max-width: 476px) {
    .hidden-xs{
        display: none !important;
    }
}

@media (min-width: 476px)  {
    .hidden-md{
        display: none !important;
    }
}

@media (min-width: 900px) {
    .inner-product-name {
        font-size: 22px;
        padding-left: 20%;
    }

    .hidden-lx-lg {
        display: none !important;
    }
}

@media (max-width: 900px) {
    .inner-product-name {
        font-size: 22px;
        text-align: center !important
    }

    .hidden-md-sm {
        display: none !important;
    }
}

.inner-product-image-block {
    text-align: center;
    display: table;
    min-height: 170px;
    margin-left: auto;
    margin-right: auto;
}

.inner-product-image {
    display: block;;
    height: 250px;
    max-width: 250px;
}

.inner-product-description {
    font-size: 16px;
}

.category {
    margin: 10px;
    background-color: white;
}

.category-name {
    font-size: 22px;
    line-height: 1.5;
}

.list-group-item {
    user-select: none;
}

.list-group input[type="checkbox"] {
    display: none;
}

.list-group input[type="checkbox"] + .list-group-item {
    cursor: pointer;
}

.list-group input[type="checkbox"] + .list-group-item:before {
    content: "\2713";
    color: transparent;
    font-weight: bold;
    margin-right: 1em;
}

.list-group input[type="checkbox"]:checked + .list-group-item {
    background-color: #0275D8;
    color: #FFF;
}

.list-group input[type="checkbox"]:checked + .list-group-item:before {
    color: inherit;
}

.filter {
    margin-top: 10px;
}

.removeProduct {
    color: red;
}

.basket {
    width: 30px;
    height: 30px;
}

.form-input-fail {
    border: 1px solid red !important;
}

.plus {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 30px;
    height: 30px;
    font: 30px/1 Arial, sans-serif;
    text-align: center;
    border-radius: 50%;
    text-decoration: none;
}

.minus {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 30px;
    height: 30px;
    font: 30px/1 Arial, sans-serif;
    text-align: center;
    border-radius: 50%;
    background-clip: padding-box;
    text-decoration: none;
}

.quantity {
    display: inline-block;
    min-width: 35%;
    text-align: center;
}

.disable-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container {
    max-width: 1248px;
}

.modal {
    overflow: auto !important;
}

.btn {
    border-radius: 0.7rem;
}

.btn-dereban {
    color: #fff;
    background-color: #343a40;
    border-color: #6c757d;
    margin-right: 5px;
}

.btn-dereban:hover {
    color: #fff;
    background-color: #1e2228;
    border-color: #545b62
}

btn-dereban.focus, .btn-dereban:focus {
    box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5)
}

.btn-dereban.disabled, .btn-dereban:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-dereban:not(:disabled):not(.disabled).active, .btn-dereban:not(:disabled):not(.disabled):active, .show > .btn-dereban.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b
}

.btn-dereban:not(:disabled):not(.disabled).active:focus, .btn-dereban:not(:disabled):not(.disabled):active:focus, .show > .btn-dereban.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5)
}

.banner {
    max-width: 100%;
}

.discount-badge {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 5px 15px;
    font-size: 1rem;
    font-weight: bold;
}

.pre-order-discount-badge {
    position: absolute;
    left: 15px;
    padding: 5px 15px;
    font-size: 1rem;
    font-weight: bold;
}

.pre-order-discount:hover {
    cursor: pointer;
}

.green-check-circle {
    color: rgb(0, 128, 55);
}

.nav-link.active {
    background-color: #343a40!important;
}

.discount-levels .nav-link.active {
    background-color: white !important;
}

.black-text .nav-link {
    color: black;
}

.product-page-info-tabs .nav-link {
    font-weight: bold;
}

.product-page-info-tabs .nav-link.active {
    background-color: #343a40!important;
    color: white;
}

.h-divider {
    margin-top: 3px;
    margin-bottom: 3px;
    height: 1px;
    width: 100%;
    border-top: 3px solid #343a40;
}

.fa-lg {
    font-size: 1.33333em;
    line-height: .75em;
    vertical-align: -0.0667em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
    -moz-appearance: textfield;
}

.count {
    color: #000;
    display: inline-block;
    vertical-align: top;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    padding: 0 2px;
    min-width: 35px;
    text-align: center;
}
.minus-product-page:hover{
    background-color: #717fe0 !important;
}
.plus-product-page:hover{
    background-color: #717fe0 !important;
}
input{
    border: 0;
    width: 2%;
}
input:disabled{
    background-color:white;
}

.product-plus {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 25px;
    height: 25px;
    font: 25px/1 Arial, sans-serif;
    text-align: center;
    border-radius: 50%;
    text-decoration: none;
}

.product-minus {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 25px;
    height: 25px;
    font: 25px/1 Arial, sans-serif;
    text-align: center;
    border-radius: 50%;
    background-clip: padding-box;
    text-decoration: none;
}

.product-minus:hover, .minus:hover, .plus:hover, .product-plus:hover {
    background-color: black !important;
}

.sortableHelper {
    z-index: 10000 !important;
}

.font-size-15px {
    font-size: 15px;
}

.font-size-20px {
    font-size: 20px;
}

.dropdown:hover {
    cursor: pointer;
}

.text-decoration-line-through {
    text-decoration: line-through;
}

.custom-dropdown-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.custom-dropdown-button {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* Ensure the button respects the flex container's width */
}

.clear-filter {
    margin-left: 5px;
    cursor: pointer;
    flex-shrink: 0; /* Prevent the 'x' from shrinking */
}

.filter-block {
    max-width: 50%; /* Set max width to 50% of the parent container */
}

.cursor-pointer {
    cursor: pointer;
}

.clicked {
    background-color: #e5ffe5; /* Change to your desired background color */
    transition: background-color 0.3s ease; /* Optional: Add a transition effect */
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%) translateX(-50%);
    }
    100% {
        transform: translateY(100%) translateX(-50%);
    }
}

.custom-alert {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    z-index: 1050;
}

@media (max-width: 768px) {
    .custom-alert {
        width: 95% !important;
    }
}

.slide-in-animation {
    animation: slideInFromTop 0.5s ease-in-out forwards;
}

.product-image-block {
    position: relative;
}

.product-badge {
    font-size: 1em !important;
}

.preorder-badge,
.online-only-badge {
    position: absolute;
    padding: 0.5em 0.75em;
    font-size: 0.9em;
    border-radius: 0.25em;
}

.preorder-badge {
    bottom: 10px;
    left: 10px;
    background-color: green;
}

.online-only-badge {
    bottom: 10px;
    left: 10px;
}

.product-image-block .preorder-badge + .webstore-exclusive-badge {
    bottom: 40px; /* Піднімаємо бейдж, якщо обидва бейджі присутні */
}

.product-images-block .preorder-badge + .webstore-exclusive-badge {
    bottom: 45px; /* Піднімаємо бейдж, якщо обидва бейджі присутні */
}

@media (max-width: 576px) {
    .preorder-badge,
    .online-only-badge {
        left: 20px !important;
    }

    .preorder-badge {
        bottom: 5px !important;
    }

    .product-image-block .preorder-badge + .webstore-exclusive-badge {
        bottom: 40px !important; /* Піднімаємо бейдж, якщо обидва бейджі присутні */
    }

    .online-only-badge {
        bottom: 5px !important;
    }

    .product-badge {
        font-size: 0.9em !important;
    }
}

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.box-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #ddd;
    padding: 10px;
}

.box-option img {
    width: 100px;
    height: auto;
}

.box-option p {
    font-weight: bold;
    margin-top: 10px;
}

.box-option.selected {
    border: 2px solid green;
}